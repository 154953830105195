<template>
  <div class="img_box_s">
    <new-header :config="headerConfig"></new-header>

    <div class="title_s" v-if="type">续签无需线上申请，购物免费获赠资格后，直接联系客服处理</div>
    <img src="../../assets/20240626-110619.png" />
  </div>
</template>
<script>
import newHeader from "@/components/newHeader";
export default {
  components: {
    newHeader
  },
  data() {
    return {
      headerConfig: {
        show: true,
        title: '联系客服',
      },
      type: ''
    };
  },
  created() {
    this.type = this.$route.query.type
  }
};
</script>
<style>
.img_box_s {
  width: 750px;
  min-height: 100vh;
  position: relative;
  box-sizing: border-box;
  background-color: #F6F6F6;
}

.img_box_s img {
  width: 100%;
}

.img_box_s .title_s {
  color: skyblue;
  font-size: 22px;
  text-align: center;
  width: 750px;
  position: absolute;
  top: 100px;
}
</style>
